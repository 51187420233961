// Imports
// ------
import React from 'react';
import loadable from '@loadable/component';
import Button from '@parts/Button';
import ButtonSecondary from '@parts/Button/ButtonSecondary';
import { Row, Column } from '@waffl';
import { StaticQuery, graphql } from 'gatsby';

// Images
// ------
import logoIcon from '@images/logoIcon.svg';

// Styles
// ------
import {
	Jacket,
	Splitter,
	CTAContent,
	FooterLinks,
	FooterAbout,
	Title,
	Text,
	LogoIcon,
	FooterHeading,
	FooterLinksTitle,
	LegalText,
	Legal,
} from './styles';

// Component
// ------
const Ticker = loadable(() => import('@parts/Ticker'));

const Footer = ({ std }) => {
	const d = new Date();
	const year = d.getFullYear();

	return (
		<StaticQuery
			query={graphql`
				query FooterQuery {
					cta: datoCmsGlobal {
						title: globalCtaTitle
						text: globalCtaText
						label: globalCtaLabel
						link: globalCtaLink
					}
					footer: datoCmsGlobal {
						desc: fountainDescription
						social {
							title: socialTitle
							url: socialUrl
						}
					}
					legal: allDatoCmsLegalPage {
						edges {
							node {
								slug
								shortTitle
							}
						}
					}
				}
			`}
			render={(data) => (
				<Jacket isNormal={std}>
					<Row isExpanded>
						<Column small={12}>
							<CTAContent pad>
								<Title isLight={!std}>{data.cta.title}</Title>
								<Text isLight={!std}>{data.cta.text}</Text>
								<Button
									isExternal
									to={data.cta.link}
									isLight={!std}
									hasIcon='arrow right'
								>
									{data.cta.label}
								</Button>
							</CTAContent>
						</Column>
					</Row>

					<Splitter />

					<Row isExpanded>
						<Column small={12} large={5} pushOnLarge={1} mpad>
							<FooterAbout padTop>
								<LogoIcon src={logoIcon} />
								<FooterHeading isLight={!std}>
									{data.footer.desc}
								</FooterHeading>
							</FooterAbout>
						</Column>

						<Column small={6} pushOnLarge={2} large={2} mpad>
							<FooterLinks padTop>
								<FooterLinksTitle isLight={!std}>
									Information
								</FooterLinksTitle>
								{data.legal.edges.map(({ node }) => (
									<ButtonSecondary
										key={node.shortTitle}
										to={`/${node.slug}/`}
										isLight={!std}
										className='btn'
									>
										{node.shortTitle}
									</ButtonSecondary>
								))}
							</FooterLinks>
						</Column>

						<Column small={6} pushOnLarge={2} large={2} padTop mpad>
							<FooterLinks padTop>
								<FooterLinksTitle isLight={!std}>
									Social
								</FooterLinksTitle>
								{data.footer.social.map(({ url, title }) => (
									<ButtonSecondary
										key={title}
										to={url}
										isLight={!std}
										className='btn'
										isExternal
									>
										{title}
									</ButtonSecondary>
								))}
							</FooterLinks>
						</Column>
					</Row>

					<Row isExpanded>
						<Column small={12} medium={10} pushOnMedium={1} mpad>
							<Legal padBottom>
								<LegalText small isLight={!std}>
									&copy; {year} Fountain
								</LegalText>
								<LegalText small isLight={!std}>
									<span>&nbsp;•&nbsp;</span>Designed and
									Engineered by &nbsp;
									<a
										href='https://makebeautifulwork.com'
										target='blank'
										rel='noopener noreferrer'
									>
										Liquid Crystal
									</a>
									,&nbsp;
									<a
										href='https://levinreigner.com'
										target='blank'
										rel='noopener noreferrer'
									>
										A Levin &amp; Riegner Company
									</a>
								</LegalText>
							</Legal>
						</Column>
					</Row>

					<Ticker std={std} />
				</Jacket>
			)}
		/>
	);
};

export default Footer;
