// Imports
// ------
import React from 'react';

// Styles
// ------
import {
	JacketSecondaryExt,
	JacketSecondaryInt,
	SweaterSecondary,
} from './styles';

// Component
// ------
const ButtonSecondary = ({ to, isLight, className, children, isExternal }) => {
	if (!isExternal)
		return (
			<JacketSecondaryInt to={to} className={className}>
				<SweaterSecondary isLight={isLight}>
					{children}
				</SweaterSecondary>
			</JacketSecondaryInt>
		);

	if (isExternal)
		return (
			<JacketSecondaryExt
				href={to}
				className={className}
				target='_blank'
				rel=' noopener noreferrer'
			>
				<SweaterSecondary isLight={isLight}>
					{children}
				</SweaterSecondary>
			</JacketSecondaryExt>
		);
};

export default React.memo(ButtonSecondary);
