// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Footer, Div, H2, H5, P, Em } from '@tackl';

// Exports
// ------
export const CTAContent = styled(Div)(
	(props) => css`
		position: relative;
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
	`
);

export const Splitter = styled.div(
	(props) => css`
		width: 100%;
		height: 1px;
		background: linear-gradient(
			90deg,
			${props.theme.bc2}00 0%,
			${props.theme.bc4} 100%
		);
	`
);

export const FooterAbout = styled(Div)(
	(props) => css`
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	`
);

export const LogoIcon = styled.img`
	width: 3.6rem;
	height: auto;
	margin-right: 2.4rem;

	${breakup.large`
		width: 8.4rem;
		margin-right: 3.6rem;
	`}
`;

export const FooterContent = styled(Div)((props) => css``);

export const FooterLinks = styled(Div)(
	(props) => css`
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		justify-content: flex-start;

		.btn {
			margin-bottom: 1.2rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	`
);

export const FooterLinksTitle = styled(Em)`
	margin-bottom: 2.4rem;
`;

export const FooterHeading = styled(H5)((props) => css``);

export const Title = styled(H2)`
	text-align: center;
`;

export const Text = styled(P)`
	text-align: center;
	margin: 2.4rem 0;

	${breakup.large` margin: 3.6rem 0; `}
`;

export const Legal = styled(Div)`
	display: block;
	margin-top: 6rem;

	${breakup.large`
		display: flex;
		justify-content: space-between;
	`}
`;

export const LegalText = styled(P)(
	(props) => css`
		display: inline;

		${breakup.large` display: inline-block;`}

		span {
			${breakup.large` display: none; `}
		}
	`
);

export const Jacket = styled(Footer)(
	(props) => css`
		position: relative;
		z-index: 1;
		background-color: ${props.isNormal
			? props.theme.bc2
			: props.theme.bc1 + `75`};
		color: ${props.isNormal ? props.theme.bc1 : props.theme.bc2};

		${props.isNormal &&
		css`
			.btn {
				span {
					&:before {
						border: 1px solid ${props.theme.bc1};
					}
				}

				&:hover span {
					border: 1px solid ${props.theme.bc1};

					&:before {
						border: 1px solid ${props.theme.bc1};
					}

					&:after {
						background: ${props.theme.bc1};
					}
				}
			}
		`}
	`
);
